<template>
    <div>
        <header-portrate />
    <div class="pa-2 m-2" style="margin-bottom:30px !important;">
        
        <bread-crumbs :items="items"></bread-crumbs>
        <tabs-comp :tabs="tabs" />
        <div :style="`direction:`+lang.dir+`;float:`+lang.lalgin+`;`+lang.lalgin+`:10px;margin:30px;`">
            <button id="multiply-button" :class="`mt-5 multiply-button-`+lang.lalgin" @click="addJV()" v-b-toggle.add_voucher>
                <div style="width:150px" :class="`multiply-button-content-`+lang.lalgin">{{lang.addJV}}</div>
            </button>
        </div>
        <div style="clear:both;margin-bottom:5px;"></div>
        <div style="margin:5px 30px 30px 30px;">
            <v-simple-table class="m-10">
                <thead>
                    <tr>
                        <th class="text-center">{{lang.date}}</th>
                        <th class="text-center">{{lang.jvnom}}</th>
                        <th class="text-center">{{lang.depit}}</th>
                        <th class="text-center">{{lang.credit}}</th>
                        <!-- <th class="text-center">{{lang.type}}</th> -->
                        <th class="text-center">{{lang.notes}}</th>
                        <th class="text-center">{{lang.action}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in jvrows" :key="index">
                        <td class="text-center">{{String(item.jv_date).substr(0,10)}}</td>
                        <td class="text-center">{{item.jvnom}}</td>
                        <td class="text-center">{{item.credit}}</td>
                        <td class="text-center">{{item.depit}}</td>
                        <!-- <td class="text-center">{{item.jv_type}}</td> -->
                        <td class="text-center">{{item.notes}}</td>
                        <td class="text-center" style="width:75px;background:#000;color:#FFF;border-bottom:2px solid #FFF !important;">
                            <b-button class="btn-sm btn" style="background:#000;color:#fff;border:none !important" @click="edit(item)" v-b-toggle.add_voucher>{{lang.view}}</b-button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th class="text-center"></th>
                        <th class="text-center"></th>
                        <th class="text-center">{{dtotal}}</th>
                        <th class="text-center">{{ctotal}}</th>
                        <!-- <th class="text-center"></th> -->
                        <th class="text-center"></th>
                        <th class="text-center"></th>
                    </tr>
                </tfoot>
            </v-simple-table>
        </div>
    </div>
    <Footer />
    <add-journal ref="addJourn" />
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import axios from 'axios';
import Footer from '@/components/Footer.vue'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import AddJournal from '@/components/add-journal.vue';
export default {
    components: { breadCrumbs, TabsComp, Footer, HeaderPortrate, AddJournal },
    data() {
        return {
            jvrows: [],
            sdate: '',
            edate: '',
            jvtype: 1,
        }
    },
    created() {
        this.getExpensesCall();
    },
    methods: {
        edit(item){
            this.$refs.addJourn.jvnom = item.jvnom;
            this.$refs.addJourn.vouchers = [];
            this.$refs.addJourn.getJV();
            this.$refs.addJourn.jvtype = this.jvtype;
        },
        addJV(){
            this.$refs.addJourn.vouchers = [];
            this.$refs.addJourn.jvtype = this.jvtype;
        },
        getExpensesCall(){
            const post = new FormData();
            post.append("type" , "getVouchers");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[sdate]",this.sdate);
            post.append("data[edate]",this.edate);
            post.append("data[type]",1);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    ///this.exprows = res.results.data.results;
                    // console.log(res);
                    this.jvrows = res.results.data;
                }
            )
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tabs: function(){
            return [
                {
                    index:0,name:this.lang.chart_of_account,href:'/finance/coa',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.finance_jvs,href:'/finance/jvs',class: 'mytab4',smclass:'font-small'
                },
                {
                    index:0,name:'أرصدة افتتاحية',href:'/finance/fient',class: 'mytab',smclass:'font-small'
                },
                // {
                //     index:1,name:this.lang.exchange_voucher,href:'/finance/outreceipt',class: 'mytab5',smclass:'font-small'
                // },
                {
                    index:0,name:this.lang.paytypes,href:'/finance/paytypes',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.fixed_assets,href:'/finance/fixed-assets',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.definition,href:'/finance/definition',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.expenses_summary,href:'/expenses/summary',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:1,name:this.lang.general_expenses,href:'/expenses/general',class: 'mytab2',smclass:'font-small'
                },
                {
                    index:1,name:this.lang.exp_management,href:'/expenses/management',class: 'mytab2',smclass:'font-small'
                },
                {
                    index:1,name:this.lang.recurring_expensess,href:'/expenses/recurring',class: 'mytab3',smclass:'font-small'
                },
                
            ]
        },
        items: function() {
            return {
                    text: this.lang.finance_jvs,
                    disabled: true,
                    to: '/finance/jvs',
                }
        },
        dtotal: function(){
            let t = 0;
            for(let i=0;i<this.jvrows.length;i++){
                t = parseFloat(this.jvrows[i].depit) + parseFloat(t);
            }
            return this.$RoundNum(t);
        },
        ctotal: function(){
            let t = 0;
            for(let i=0;i<this.jvrows.length;i++){
                t = parseFloat(this.jvrows[i].credit) + parseFloat(t);
            }
            return this.$RoundNum(t);
        },
        
    },
}
</script>